<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12 mb-0">
                <div class="card">
                    <div class="card-body">
                        <div class="float-sm-left mr-3">
                            <router-link
                                    to="/prescriptions"
                                    class="btn btn-info"
                            ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                            </router-link >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-12 mx-0">
                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Patient's details
                                </template>
                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th> Name</th>
                                                <th> Phone number</th>
                                                <th>Gender</th>
                                                <th>Date of birth</th>
                                                <th>Date registered</th>
                                            </tr>
                                            </thead>
                                            <tbody >
                                              <tr v-if="patient !== null">
                                                  <td class="d-none">{{patient.id}}</td>
                                                  <td> <span class="text-primary" style="cursor: pointer; border-bottom: 2px dotted #029F96;" v-if="patient.identity !== null" ><router-link :to="'/patients/'+patient.id" >{{patient.identity.surname}} {{patient.identity.othernames}} </router-link></span></td>
                                                  <td> <span class="badge badge-outline-primary p-1" v-if="patient.identity !== null">{{patient.identity.phoneNumber}} </span></td>
                                                  <td><span class="badge badge-outline-primary p-1" v-if="patient.identity !== null">{{patient.identity.gender}}</span></td>
                                                 <td> <span class="badge badge-outline-danger p-1" v-if="patient.identity !== null">{{patient.identity.dob}} </span></td>
                                                  <td> <span class="font-12 font-weight-bold text-dark"  v-if="patient.identity !== null"> {{patient.identity.createdAt | moment('MMMM Do YYYY')}}</span></td>
                                              </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Prescription History
                                </template>

                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th>Prescription Name</th>
                                                <th> Image</th>
                                                <th>Date created</th>
                                            </tr>
                                            </thead>
                                            <tbody >
                                            <tr v-if="patient !== null" v-for="p in prescriptionList">
                                                <td class="d-none">{{p.id}}</td>
                                                <td> <span class="text-primary" style="cursor: pointer; border-bottom: 2px dotted #029F96;" v-if="p.product !== null"> <router-link :to="'/product/'+p.product.id">{{p.product.productName}}</router-link></span></td>
                                                <td> <span v-if="p.product !== null"><img  class="rounded mr-3" height="48" :src="p.product.mainImage.imageUrl"></span></td>
                                                <td> <span class="font-12 font-weight-bold text-dark"  v-if="p.createdAt !== null"> {{p.createdAt | moment('MMMM Do YYYY')}}</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Doctor
                                </template>

                                <div>
                                    <div class="table-responsive mb-0">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                            <tr>
                                                <th> Name</th>
                                                <th> Phone number</th>
                                                <th>Gender</th>
                                                <th>Date of birth</th>
                                                <th>Date registered</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-if="doctor !== null">
                                                    <td class="d-none" v-if="doctor !== null">{{doctor.id}}</td>
                                                    <td> <span v-if="doctor.identity !== null"><router-link class="text-primary mr-2" style="cursor: pointer; border-bottom: 2px dotted #029F96;" :to="'/doctors/'+doctor.id">{{doctor.identity == null ? "" :  doctor.identity.surname}} {{doctor.identity == null ? "" : doctor.identity.othernames}}</router-link></span></td>
                                                    <td> <span class="badge badge-outline-primary" v-if="doctor.identity !== null"> {{doctor.identity == null ? "" : doctor.identity.phoneNumber}} </span></td>
                                                    <td> <span v-if="doctor.identity !== null"  class="badge badge-outline-primary">{{doctor.identity == null ? "" : doctor.identity.gender}} </span></td>
                                                    <td> <span v-if="doctor.identity !== null"  class="badge badge-outline-danger">{{doctor.identity == null ? "" : doctor.identity.dob}} </span></td>
                                                    <td> <span class="font-12 font-weight-bold text-dark"> {{doctor.identity == null ? "" : doctor.identity.createdAt | moment('MMMM Do YYYY')}}</span> </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>

                <div class="card mb-2">
                    <div class="card-body">
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Prescription Image
                                </template>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <img :src="prescriptionImage" height="250" width="300" />
<!--                                        <enlargeable-image :src="prescriptionImage" :src_large="prescriptionImage" class="resizable-image" />-->
                                    </div>
                                </div>

                            </b-tab>
                        </b-tabs>
                    </div>
                </div>

                <div class="card mb-2">

                    <div class="card-body">
                        <div><button class="float-right btn btn-outline-success" v-b-modal.modal-lg-product>Add product</button></div>
                        <b-modal
                                id="modal-lg-product"
                                size="lg"
                                title="Search products"
                                title-class="font-18"
                                hide-footer
                        >
                                   <div class="col-md-12">
                                        <div class="form-group mb-4">
                                            <label for="name"> Search for products</label>
                                            <input
                                                    class="form-control"
                                                    v-model="searchProduct"
                                                    type="text"
                                                    id="searchProduct"
                                                    name="searchProduct"
                                                    placeholder="Search for products"
                                                    @input="debouncedSearchForProductName"
                                            />
                                        </div>
                                       <div class="d-block mb-3">
                                            <div class="row">
                                                <div class="col-lg-6 "> <h2 class="float-left">Selected Products</h2></div>
                                                <div class="col-lg-6" v-if="selectedProducts.length >0">
                                                    <button class="btn btn-success float-right" @click="AddProductsToPrescriptionTable">Add to grid</button>
                                                </div>
                                            </div>
                                           <ul class="list-group" v-for="p in selectedProducts" :key="p.id">
                                               <li class="list-group-item">{{p.id}} {{p.name}} <span @click="removeSelectedProductFromList(p.id)" class="text-danger fa fa-trash float-right" style="cursor: pointer;"></span></li>
                                           </ul>
                                       </div>
                                <div class="table-responsive mb-0">
                                    <lottie-loader :loading="showLoaderPrescription" />
                                    <table class="table table-borderless mb-0">
                                        <thead class="thead-light">
                                        <tr>
                                            <th></th>
                                            <th>Product ID</th>
                                            <th>Product Image</th>
                                            <th>Product Name</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(product,index) in searchProducts" :key="index">
                                            <td><input @change="getSelectedProduct(product)" v-model="checkedProducts[product.id]" type="checkbox" /> </td>
                                            <td><img v-if="product.mainImage" :src="product.mainImage.imageUrl" width="50" height="50" /></td>
                                            <td>{{product.id}}</td>
                                            <td><span class="p-1"> {{product.productName}}</span></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </b-modal>
                        <b-tabs content-class="mt-1" pills class="navtab-bg">
                            <b-tab active>
                                <template v-slot:title>
                                    <i class=" mr-1"></i>Product List
                                </template>
                                <div>
                                    <div>
                                        <div class="table-responsive mb-0">
                                            <table class="table table-borderless mb-0">
                                                <thead class="thead-light">
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th> Image</th>
                                                    <th>Dosage duration</th>
<!--                                                    <th>Status</th>-->
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                <tr v-if="p !== null" v-for="p in selectedProductsForPrescription">
                                                    <td class="d-none">{{p.id}}</td>
                                                    <td> <span class="text-primary" style="cursor: pointer; border-bottom: 2px dotted #029F96;"> {{p.name}}</span></td>
                                                    <td> <span v-if="p.image !== null"><img  class="rounded mr-3" width="45" height="45" :src="p.image"></span></td>
                                                    <td> <span class="font-12 font-weight-bold text-dark"> {{p.dosageDuration}}</span></td>
<!--                                                    <td> <span class="font-12 font-weight-bold"> {{prescriptionProductStatus}}</span></td>-->
                                                    <td>
                                                        <button @click="updateProductInfo(p)" class="btn btn-secondary" v-b-modal.modal-lg-prescription>
                                                            {{ prescriptionProductStatus === 'Editing' ? 'Update prescription' : 'Edit record' }}
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>

                                                <b-modal
                                                        id="modal-lg-prescription"
                                                        size="lg"
                                                        title="Update prescription"
                                                        title-class="font-18"
                                                        hide-footer
                                                >
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="form-group mb-3">
                                                                    <label for="name">Product Name</label>
                                                                     <span class="d-none"> {{addPrescriptionToProduct.productId}}</span>
                                                                    <input
                                                                            id="name"
                                                                            class="form-control"
                                                                            v-model="addPrescriptionToProduct.productName"
                                                                            readonly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                 <div class="row">
                                                                     <div class="col-lg-6">
                                                                         <div class="form-group mb-3">
                                                                             <label for="quantityOfTablet">Quantity of tablet</label>
                                                                             <input
                                                                                     class="form-control"
                                                                                     v-model="addPrescriptionToProduct.quantityOfTablet"
                                                                                     name="quantityOfTablet"
                                                                                     type="text"
                                                                                     id="quantityOfTablet"
                                                                                     placeholder="Enter quantity of tablet"
                                                                                     v-validate="'required|decimal'"
                                                                                     :class="{'is-invalid': errors.has('quantityOfTablet') }"
                                                                                     step="any"
                                                                             />
                                                                             <span v-show="errors.has('quantityOfTablet')" class="help text-danger">{{ errors.first('quantityOfTablet') }}</span>
                                                                         </div>
                                                                     </div>
                                                                     <div class="col-lg-6">
                                                                         <div class="form-group mb-3">
                                                                             <label for="dosageFrequencyId">Frequency Of Dosage</label>
                                                                             <select name="dosageFrequencyId" id="dosageFrequencyId" v-model="addPrescriptionToProduct.dosageFrequencyId" class="form-control" v-validate="'required'">
                                                                                 <option v-for="f in dosageFrequencyList" :key="f.id" :value="f.id">{{f.name}}</option>
                                                                             </select>
                                                                             <span v-show="errors.has('dosageFrequencyId')" class="help text-danger">{{ errors.first('dosageFrequencyId') }}</span>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group mb-3">
                                                                    <label for="dosageRouteId">Dosage route</label>
                                                                    <select name="dosageRouteId" id="dosageRouteId" v-model="addPrescriptionToProduct.dosageRouteId" class="form-control" v-validate="'required'">
                                                                        <option v-for="f in dosageRouteList" :key="f.id" :value="f.id">{{f.name}}</option>
                                                                    </select>
                                                                    <span v-show="errors.has('dosageRouteId')" class="help text-danger">{{ errors.first('dosageRouteId') }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                     <div class="row">
                                                                         <div class="col-lg-6">
                                                                             <div class="form-group mb-3">
                                                                                 <label for="dosageDuration">Dosage duration</label>
                                                                                 <input
                                                                                         class="form-control"
                                                                                         v-model="addPrescriptionToProduct.dosageDuration"
                                                                                         name="dosageDuration"
                                                                                         type="text"
                                                                                         id="dosageDuration"
                                                                                         placeholder="Enter dosage duration"
                                                                                         v-validate="'required|decimal'"
                                                                                         :class="{'is-invalid': errors.has('dosageDuration') }"
                                                                                         step="any"
                                                                                 />
                                                                                 <span v-show="errors.has('dosageDuration')" class="help text-danger">{{ errors.first('dosageDuration') }}</span>
                                                                             </div>
                                                                         </div>
                                                                         <div class="col-lg-6">
                                                                             <div class="form-group mb-3">
                                                                                 <label for="dosageAmount">Dosage amount</label>
                                                                                 <input
                                                                                         class="form-control"
                                                                                         v-model="addPrescriptionToProduct.dosageAmount"
                                                                                         name="dosageAmount"
                                                                                         type="text"
                                                                                         id="dosageAmount"
                                                                                         placeholder="Enter dosage amount"
                                                                                         v-validate="'required|decimal'"
                                                                                         :class="{'is-invalid': errors.has('dosageAmount') }"
                                                                                         step="any"
                                                                                 />
                                                                                 <span v-show="errors.has('dosageAmount')" class="help text-danger">{{ errors.first('dosageAmount') }}</span>
                                                                             </div>
                                                                         </div>
                                                                     </div>
                                                            </div>

                                                             <div class="col-lg-12">
                                                                 <div class="form-group mb-3">
                                                                     <label for="refillInstruction">Refill instruction</label>
                                                                 <textarea class="form-control" v-validate="'required'" name="refillInstruction" id="refillInstruction" cols="30" v-model="addPrescriptionToProduct.refillInstruction"
                                                                           rows="2"></textarea>
                                                                     <span v-show="errors.has('refillInstruction')" class="help text-danger">{{ errors.first('refillInstruction') }}</span>
                                                                 </div>
                                                             </div>
                                                             <div class="col-lg-6">
                                                                 <div class="form-group mb-3">
                                                                     <label for="numberOfDaysToRefill">Number of days to refill</label>
                                                                     <input
                                                                             class="form-control"
                                                                             v-model="addPrescriptionToProduct.numberOfDaysToRefill"
                                                                             name="numberOfDaysToRefill"
                                                                             type="text"
                                                                             id="numberOfDaysToRefill"
                                                                             placeholder="Number of days to refill"
                                                                             v-validate="'required|decimal'"
                                                                             :class="{'is-invalid': errors.has('numberOfDaysToRefill') }"
                                                                             step="any"
                                                                     />
                                                                     <span v-show="errors.has('numberOfDaysToRefill')" class="help text-danger">{{ errors.first('numberOfDaysToRefill') }}</span>
                                                                 </div>
                                                             </div>
                                                             <div class="col-lg-6">
                                                                 <div class="form-group mb-3">
                                                                     <label for="dosagePerDose">Dosage per dose</label>
                                                                     <input
                                                                             class="form-control"
                                                                             v-model="addPrescriptionToProduct.dosagePerDose"
                                                                             name="dosagePerDose"
                                                                             type="text"
                                                                             id="dosagePerDose"
                                                                             placeholder="Dosage per dose"
                                                                             v-validate="'required'"
                                                                             :class="{'is-invalid': errors.has('dosagePerDose') }"
                                                                     />
                                                                     <span v-show="errors.has('dosagePerDose')" class="help text-danger">{{ errors.first('dosagePerDose') }}</span>
                                                                 </div>
                                                             </div>

                                                            <div class="col-lg-12">
                                                                <div class="form-group mb-3">
                                                                    <label for="additionalInformation">Additional information</label>
                                                                    <textarea class="form-control" v-validate="'required'" name="additionalInformation" id="additionalInformation" cols="30" v-model="addPrescriptionToProduct.additionalInformation"
                                                                              rows="2"></textarea>
                                                                    <span v-show="errors.has('additionalInformation')" class="help text-danger">{{ errors.first('additionalInformation') }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <button type="submit" @click="UpdatePrescription" class="btn btn-success"> Update prescription</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-modal>
                                            </table>
                                        </div>
                                        <div class="row float-right mt-5">
                                            <div class="col-lg-12">
                                                <button class="btn btn-success float-right" @click="SubmitPrescription" v-if="selectedProductsForPrescription.length  > 0 && hideOrShowSubmitButton"> Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end tab-pane -->
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-box-->
                </div>
            </div>

        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";
    import SweetAlertConfig from "../../../../SwalConfig";
    import EnlargeableImage from '@diracleo/vue-enlargeable-image';
    import SweetAlertWrapper from "../../../../sweetAlert";

    export default {
        name: "ViewAndUpdatePrescription",
        components : {
            Layout,
            PageHeader,
            LottieLoader,
            EnlargeableImage
        },
        data() {
            return {
                showLoaderPrescription : false,
                hideOrShowSubmitButton : false,
                title: "View and update prescription",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "prescription",
                        active: true,
                    },
                ],
                dosageFrequencyList : [],
                dosageRouteList : [],
                prescriptionProductStatus : 'Editing',
                checkedProducts: {},
                selectedProducts : [],
                searchProduct :'',
                selectedProductsForList : [],
                prescriptionList : [],
                prescriptionId : '',
                prescriptionImage : '',
                prescriptionHistoryList : [],
                searchProducts : [],
                selectedProductsForPrescription : [],
                patient : {},
                doctor : {},
                selectedPrescription : {},

                addPrescriptionToProduct : {
                    productId: '',
                    productName : '',
                    quantityOfTablet : 0,
                    dosageFrequencyId:'',
                    dosageRouteId:'',
                    dosageDuration : 0,
                    dosageAmount : 0,
                    additionalInformation :'',
                    numberOfDaysToRefill : 0,
                    refillInstruction: '',
                    dosagePerDose : '',

                }
            }
        },
        methods : {
            getDosageFrequency() {
                BaseUrl.AxiosBearer(`/pharmacy?page=1&pageSize=10&searchText=''`).then((resp) => {
                    if(resp) {
                        this.dosageFrequencyList = []
                        this.dosageFrequencyList = resp.data.payload.filter(x => x.type==='FREQUENCY')
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            getDosageRouteS() {
                this.showLoader = true
                BaseUrl.AxiosBearer(`/pharmacy?page=1&pageSize=10&searchText=''`).then((resp) => {
                    this.showLoader = false
                    if(resp) {
                        this.dosageRouteList = []
                        this.dosageRouteList = resp.data.payload.filter(x => x.type==='ROUTE')
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            updateProductInfo(p) {
                 console.log(" product info : ", p)
                this.addPrescriptionToProduct.productName = p.name
                this.addPrescriptionToProduct.productId = p.id;
                this.addPrescriptionToProduct.additionalInformation = p.additionalInformation;
                this.addPrescriptionToProduct.dosageFrequencyId = p.dosageFrequencyId;
                this.addPrescriptionToProduct.dosagePerDose = p.dosagePerDose;
                this.addPrescriptionToProduct.dosageAmount = p.dosageAmount;
                this.addPrescriptionToProduct.dosageDuration = p.dosageDuration;
                this.addPrescriptionToProduct.dosageRouteId = p.dosageRouteId;
                this.addPrescriptionToProduct.refillInstruction = p.refillInstruction;
                this.addPrescriptionToProduct.numberOfDaysToRefill = p.numberOfDaysToRefill;
            },
            getPrescriptionData() {
                this.showLoader = true
                BaseUrl.AxiosBearer.get(`/admin/prescriptions/${this.$route.params.Id}?prescriptionId=${this.$route.params.Id}&page=1&pageSize=1000000`).then((resp) => {
                    this.showLoader = false
                    if(resp.data.status) {

                        this.prescriptionList = []
                         //console.log(" prescription data : ", resp.data.payload.items)
                        this.prescriptionList = resp.data.payload.items

                        this.patient = resp.data.payload.patient

                        this.prescriptionImage = resp.data.payload.prescriptionImage;
                        this.prescriptionId = resp.data.payload.id;
                        //console.log(" selected pres image : ", this.prescriptionImage)
                        this.doctor = resp.data.payload.doctor
                    }

                }).catch((error) => {
                    this.$bvToast.toast(error, ToastConfigAlert)
                })
            },

            getSelectedProduct(product) {

                let isChecked = this.checkedProducts[product.id] || false;
                if(isChecked) {
                    // I added the extra fields to avoid adding another field when the prescription on th table is updated
                    this.selectedProducts.push(
                        { id: product.id, name: product.productName , image : product.mainImage.imageUrl,
                            quantityOfTablet : 0,
                            dosageFrequencyId:'',
                            dosageRouteId:'',
                            dosageDuration : 0,
                            dosageAmount : 0,
                            additionalInformation :'',
                            numberOfDaysToRefill : 0,
                            refillInstruction: '',
                            dosagePerDose : '',
                        })
                }
                else {
                    this.selectedProducts = this.selectedProducts.filter(x => x.id !== product.id)
                }
            },
            removeSelectedProductFromList(id) {
                this.selectedProducts = this.selectedProducts.filter(x => x.id !== id)
            },
            getDosageFrequencyList() {
                this.showLoader = true
                BaseUrl.AxiosBearer.get(`/pharmacy?/&page=1&pageSize=1000`).then((resp) => {
                    this.showLoader = false
                    if(resp.data.status) {
                        this.dosageFrequencyList = []
                        this.dosageFrequencyList = resp.data.payload
                       // console.log(" List data : ", this.dosageFrequencyList)
                    }

                }).catch((error) => {
                    this.$bvToast.toast(error, ToastConfigAlert)
                })
            },
            SubmitPrescription(){
                this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                    title : "Submit prescription ?",
                    confirmButtonText :"Yes Submit"
                }), () => {
                    console.log(" prescription data : ", this.selectedProductsForPrescription)
                    const prescriptonData = {
                        prescriptionId: parseInt(this.prescriptionId),
                        patientId: parseInt(this.patient.id),
                        prescriptionImage: this.prescriptionImage,
                        items:  this.selectedProductsForPrescription.map((data) => ({
                            productId: data.productId,
                            quantityOfTablets: data.quantityOfTablet,
                            dosageFrequencyId: data.dosageFrequencyId,
                            dosageRouteId: data.dosageRouteId,
                            dosageDuration: data.dosageDuration,
                            dosageAmount: data.dosageAmount,
                            additionalInformation: data.additionalInformation,
                            numberOfDaysToRefill: data.numberOfDaysToRefill,
                            refillInstruction: data.refillInstruction,
                            dosagePerDose: data.dosagePerDose
                        })),
                    }


                    this.showLoaderPrescription = true
                    BaseUrl.AxiosBearer.put(`/admin/prescriptions/${this.prescriptionId}?page=1&pageSize=100`, prescriptonData).then((resp) => {
                        this.showLoaderPrescription = false
                        if(!resp.data.status) {
                            this.$bvToast.toast(resp.data.message , ToastConfigAlert)
                        }
                        else {
                            this.sweetAlert.showMessage("Prescription updated!  ",resp.data.message)
                            this.hideOrShowSubmitButton = false;
                            this.clearControls()
                        }

                    }).catch(({ response: error }) => {
                        console.log(" error : ", error)
                        let { message } = error.data;

                        if(typeof message == 'object') {
                            message = message.join('\n\n')
                            console.log('entered')
                        }

                        console.log(" error : ", message)
                        this.showLoader = false
                        this.$bvToast.toast(message , ToastConfigAlert)

                    })

                    return;
                })
            },
            debouncedSearchForProductName(){
                clearTimeout(this.debounceTimeout);

                // Set a new timeout to execute the search after 500 milliseconds
                this.debounceTimeout = setTimeout(() => {
                    this.GetProductDetailsByProductName(this.searchProduct);
                }, 500);
            },
            GetProductDetailsByProductName(name) {

                BaseUrl.AxiosBearer(`store/search?searchModule=PRODUCTS&classification=PHARMACY&page=1&pageSize=5&searchText=${name}`).then((resp) => {
                    if(resp) {
                        this.searchProducts = []
                        this.searchProducts = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            UpdatePrescription() {
                 //console.log("selected product : ", this.addPrescriptionToProduct.productId)
                let updatedPrescription =  this.selectedProductsForPrescription.find(x => x.id === this.addPrescriptionToProduct.productId)
                updatedPrescription.additionalInformation = this.addPrescriptionToProduct.additionalInformation
                updatedPrescription.dosageAmount = this.addPrescriptionToProduct.dosageAmount
                updatedPrescription.dosageDuration = this.addPrescriptionToProduct.dosageDuration;
                updatedPrescription.dosageFrequencyId = this.addPrescriptionToProduct.dosageFrequencyId;
                updatedPrescription.dosagePerDose = this.addPrescriptionToProduct.dosagePerDose;
                updatedPrescription.dosageRouteId = this.addPrescriptionToProduct.dosageRouteId;
                updatedPrescription.numberOfDaysToRefill = this.addPrescriptionToProduct.numberOfDaysToRefill;
                updatedPrescription.quantityOfTablet = this.addPrescriptionToProduct.quantityOfTablet;
                updatedPrescription.refillInstruction = this.addPrescriptionToProduct.refillInstruction;
                updatedPrescription.dosageRouteId = this.addPrescriptionToProduct.dosageRouteId;
               // console.log("list of prescription product after : ", this.selectedProductsForPrescription)
                this.hideOrShowSubmitButton = true;
                this.clearPrescriptionModelControls()
            },
            clearPrescriptionModelControls(){
                 this.$bvModal.hide('modal-lg-prescription')
                this.addPrescriptionToProduct = {
                    productId: '',
                    productName : '',
                    quantityOfTablet : 0,
                    dosageFrequencyId:'',
                    dosageRouteId:'',
                    dosageDuration : 0,
                    dosageAmount : 0,
                    additionalInformation :'',
                    numberOfDaysToRefill : 0,
                    refillInstruction: '',
                    dosagePerDose : '',
                }
                this.$validator.reset();
            },
            AddProductsToPrescriptionTable() {

                this.selectedProductsForPrescription = this.selectedProducts
                this.searchProduct = ''
                this.searchProducts = [];
                this.selectedProducts = [];
                this.checkedProducts = {}
                this.$bvModal.hide('modal-lg-product');
            }

        },

        created() {
            this.getPrescriptionData();
            this.getDosageFrequencyList()
            this.getDosageFrequency()
            this.getDosageRouteS()
            this.sweetAlert = new SweetAlertWrapper()
        }

    }
</script>

<style scoped>

</style>